import { Field, useField } from "formik";
import React, { useState, useContext } from "react";
import { AllDataContext } from "../../lib/dataContext";
import { DealershipResponsibilityArea } from "../../../../common/typedefs";
import {
  Abbr,
  FieldContainer,
  FieldErrorText,
} from "../../forms/test-drive/personalia/styles";
import { sendToGTM } from "../../lib/sendToGTM";
import LoadingSpinner from "../loading-spinner";
import {
  ChosenDealerContainer,
  Dropdown,
  Error,
  PickOtherDealerButton,
} from "./styles";

interface DealerLookupProps {
  noDealerFoundText: string;
}

const DealerLookup = ({ noDealerFoundText }: DealerLookupProps) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [dealerList, setDealerList] = useState<
    DealershipResponsibilityArea[] | []
  >([]);
  const [dealerFound, setDealerFound] = useState<boolean>(true);
  const [chosenDealer, setChosenDealer] =
    useState<DealershipResponsibilityArea | null>(null);
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [dealerLookupError, setDealerLookupError] = useState<boolean>(false);
  const [fetchStatus, setFetchStatus] = useState<
    "uninitiated" | "pending" | "success" | "failed"
  >("uninitiated");
  const [, meta, helpers] = useField("dealer");
  const { touched, error } = meta;
  const { setValue } = helpers;
  const { brandCode } = useContext(AllDataContext);
  const checkNumber = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 4) {
      setFetchStatus("uninitiated");
      setDisabled(true);
      setDealerLookupError(false);
      try {
        setFetchStatus("pending");
        const res = await fetch(
          `/api/fdb/getdealersforsales?postcode=${e.target.value}`
        );

        setFetchStatus("success");
        setDisabled(false);
        const resToJson: {
          dealers: DealershipResponsibilityArea[];
          dealerFound: boolean;
        } = await res.json();
        setDealerList(resToJson.dealers);
        setDealerFound(resToJson.dealerFound);

        const dealerCode = new URL(window.location.href).searchParams.get(
          "dealer"
        );
        let dealerFromUrlFound = null;
        if (dealerCode) {
          const dealer: DealershipResponsibilityArea = resToJson.dealers.find(
            (dealer) => dealer.DealerNo === dealerCode
          )!;
          if (dealer) {
            // Move dealer to the start of list so it shows as the chosen option in the select dropdown as well.
            let dealers = resToJson.dealers.filter(dealer => dealer.DealerNo !== dealerCode)
            dealers.unshift(dealer)
            setDealerList(dealers)
            setChosenDealer(dealer);
            setValue(dealer);
            sendToGTM({ event: "zip_code", dealer: dealer });
            dealerFromUrlFound = true;
            setDealerFound(true);
          }
        }
        if (!dealerFromUrlFound) {
          setChosenDealer(resToJson.dealers[0]);
          setValue(resToJson.dealers[0]);
          sendToGTM({ event: "zip_code", dealer: resToJson.dealers[0] });
        }
      } catch (error) {
        setFetchStatus("failed");
        setValue("");
        setDealerLookupError(true);
        setDisabled(false);
        sendToGTM({
          event: "error",
          field_name: "Dealer lookup",
          error_code: "400",
          error_text: error,
        });
      }
    } else {
      setChosenDealer(null);
      setValue("");
      setDealerList([]);
    }
  };
  const updateChosenDealer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const v = dealerList.filter(
      (dealer: DealershipResponsibilityArea) =>
        dealer.DealerNo === e.target.value
    );
    setChosenDealer(v[0]);
    setValue(v[0]);
    sendToGTM({ event: "zip_code", dealer: v[0] });
  };
  return (
    <>
      <FieldContainer>
        <label htmlFor="dealerLookupField" className="p2">
          Finn en forhandler i nærheten
          <Abbr title="Påkrevd">*</Abbr>
        </label>
        <Field
          maxLength={4}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkNumber(e)}
          placeholder="Fyll inn postnummer"
          id="dealerLookupField"
          name="dealerLookupField"
          type="text"
          autoComplete="postal-code"
          disabled={disabled}
          inputMode="numeric"
        />
      </FieldContainer>
      {touched && error ? <FieldErrorText>{error}</FieldErrorText> : null}
      {dealerLookupError && !chosenDealer && (
        <FieldErrorText>
          Noe gikk galt under forhandlersøk, vennligst prøv på nytt
        </FieldErrorText>
      )}
      {fetchStatus === "pending" && <LoadingSpinner />}
      {chosenDealer && (
        <div>
          <p className="p2">Valgt forhandler:</p>
          {dealerFound ? (
            <ChosenDealerContainer>
              {brandCode === "OPE" ? (
                <img
                  alt="Chevron.svg"
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNC45ODM0IDE5LjQ2MjRIMTkuODIyNkwxNC43MDA0IDcuNDYyNEgxOS44NzA5TDI0Ljk4MzQgMTkuNDYyNFpNMTkuODcwOSAzMS40NjI0TDI0Ljk4MzQgMTkuNDYyNEgxOS44MjI2TDE0LjcwMDQgMzEuNDYyNEgxOS44NzA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
                  width="24"
                  height="24"
                />
              ) : (
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 22 18"
                  width="24"
                  height="24"
                >
                  <path
                    d="M19.39.938L7.063 13.265l-4.5-4.547c-.234-.188-.609-.188-.796 0l-1.36 1.36c-.187.187-.187.562 0 .796l6.282 6.234a.54.54 0 00.796 0L21.547 3.047c.187-.188.187-.563 0-.797L20.187.937c-.187-.234-.562-.234-.796 0z"
                    fill="#00A3E0"
                  />
                </svg>
              )}

              <h6>{chosenDealer.DealerName}</h6>
              <p className="p2">{chosenDealer.Address}</p>
            </ChosenDealerContainer>
          ) : (
            <Error>{noDealerFoundText}</Error>
          )}
          {dealerFound && (
            <PickOtherDealerButton
              type="button"
              className="p2"
              onClick={() => setShowSelect(!showSelect)}
            >
              Velg en annen forhandler
            </PickOtherDealerButton>
          )}
          {(showSelect || !dealerFound) && (
            <Dropdown name="dealerDropdown" onChange={updateChosenDealer}>
              {dealerList?.map((dealer: DealershipResponsibilityArea) => (
                <option key={dealer.DealerNo} value={dealer.DealerNo}>
                  {dealer.DealerName}
                </option>
              ))}
            </Dropdown>
          )}
        </div>
      )}
    </>
  );
};

export default DealerLookup;
