import { Field, FormikErrors, FormikTouched } from "formik";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { GTMCar, TestDriveTexts } from "../../../../../common/typedefs";
import { Divider, InfoContainer } from "../../../components/car-card/styles";
import DealerLookup from "../../../components/dealer-lookup";
import { urlFor } from "../../../lib/imageBuilder";
import {
  CarInfo,
  TestDriveContext,
  TestDrivePersonaliaValues,
} from "../../../pages/test-drive";
import {
  BlockContentContainer,
  InputContainer,
} from "../../../styles/containers";
import {
  Abbr,
  CarInfoContainer,
  Container,
  CustomLink,
  FieldContainer,
  FieldErrorText,
  ImageInfoContainer,
  NewsLetterContainer,
  PageLayout,
  SubmitButtonTestDrive,
  TestDriveBackButton,
  TestDriveH1,
  TestDriveIngress,
  TitleContainer,
  TestDriveLayout,
  SubmitButtonTestDriveOpel,
  TilbudLayout,
  HeadingContainer,
} from "./styles";
//@ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { getBrand } from "../../../lib/getBrandFromCode";
import { OfferPageContext } from "../../../pages/offer";
import { sendToGTM } from "../../../lib/sendToGTM";
import { AllDataContext } from "../../../lib/dataContext";

interface Props {
  submitting: boolean;
  texts: TestDriveTexts;
  errors: FormikErrors<TestDrivePersonaliaValues>;
  touched: FormikTouched<TestDrivePersonaliaValues>;
  isValid: boolean;
  carInfo: CarInfo | null;
  pageType: string;
}

const TestDrivePersonalia = (props: Props) => {
  const { submitting, texts, errors, touched, carInfo, pageType } = props;
  const { brandCode } = useContext(AllDataContext);

  const [windowSize, setwindowSize] = useState<number>(window.innerWidth);

  const checkContext: any = () => {
    return props.pageType === "offer" ? OfferPageContext : TestDriveContext;
  };

  const { setStep, selectedCar } = useContext<any>(checkContext());

  useLayoutEffect(() => {
    const updateSize = () => {
      setwindowSize(window.innerWidth);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const checkVPVtitle = () => {
    return pageType === "offer"
      ? `Få tilbud på en ${getBrand(brandCode)} ${selectedCar}`
      : `Prøvekjør en ${getBrand(brandCode)} ${selectedCar}`;
  };

  const checkVPVpath = () => {
    return pageType === "offer"
      ? "/tilbud/personopplysninger"
      : "/book-provekjoring/personopplysninger";
  };

  const gtmCar: GTMCar = {
    brand: getBrand(brandCode),
    model: carInfo?.modelSeries as string,
    trim: selectedCar,
    fuel: carInfo?.fuel as string,
    car_type: carInfo?.chassis as string,
    segment: carInfo?.segment as string,
    carline_code: (carInfo?.carlineCode as string) ?? "empty",
  };

  useEffect(() => {
    sendToGTM({
      event: "vpv",
      vpv_title: checkVPVtitle(),
      vpv_path: checkVPVpath(),
      form_name: pageType === "offer" ? "tilbud" : "prøvekjøring",
      car: gtmCar,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const serializers = {
    marks: {
      link: ({ mark, children }: any) => {
        return (
          <CustomLink href={mark.href} target="_blank" rel="noreferrer">
            {children}
          </CustomLink>
        );
      },
    },
  };

  return (
    <>
      {carInfo ? (
        <PageLayout>
          <TitleContainer>
            {pageType === "testdrive" ? (
              brandCode === "OPE" ? (
                <TestDriveLayout>
                  <h1 className="title-h1-OpelLight">Prøvekjør en</h1>
                  <HeadingContainer>
                    <h1 className="title-h1-OpelBold">
                      {getBrand(brandCode)} {selectedCar}{" "}
                    </h1>
                    <img
                      alt="Slash.png"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAYAAAB0HkOaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgBxZjLUcMwEIZ/mcAkwyUHuDsd0AGUkBLSAXQAdEA6gA5CBbiEdEDOPIbcciDjZUNeq4ljW1rJ/i6xrId3/uibRDZQsqB+CnTeoWY5SKAkx8kDlCSg556Zz1TF/FC/zwtcQ4lBMl4XpaCL0yEvlUIBAdMz8zlVF8Nf0j2UGJjx/tqTBV3c8McbVNCsZ74H25Z3MgS6gxJOZWK3PQip88qibcsrmZA62/e8FtLrnMO8FKzrxoIuRyF07pmvDNpiQuts33cghs4Sp2Ry0AhK+IHZsb7aycTSWVI7mVg62/21F4qjs6SDGqx1phQq/jduVjaiZjJ6nflRj1UjKjdwbJ0llcnE1llSmkwTOktKk2lCZ3tseWd0nSVH1W5KZ0lJMs3oLClMZqNzChU062I5cZlRmEwonY2Zz13mHKjdtM6Sg2RC6Mx/K19dCyksJszZGU/wwNrAbegssZLhU+It1LjpbM3cXqx05nivoIDnsz2/GTzZFRNCZwOa+Gzc/Xy0q7MkWVfUCbBXkGkK2RXDG3cIJfnmVZiqmBBn55XO5+bD6XeosJi2dZb8Aef4sLEchV9aAAAAAElFTkSuQmCC"
                    />
                  </HeadingContainer>
                </TestDriveLayout>
              ) : (
                <TestDriveH1>
                  {brandCode === "DS"
                    ? `Prøvekjør ${selectedCar}`
                    : `Prøvekjør en ${getBrand(brandCode)} ${selectedCar}`}{" "}
                </TestDriveH1>
              )
            ) : brandCode === "OPE" ? (
              <TilbudLayout>
                <h1 className="title-h1-OpelLight">Få tilbud på en</h1>
                <HeadingContainer>
                  <h1 className="title-h1-OpelBold">
                    {getBrand(brandCode)} {selectedCar}{" "}
                  </h1>
                  <img
                    alt="Slash.png"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAYAAAB0HkOaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgBxZjLUcMwEIZ/mcAkwyUHuDsd0AGUkBLSAXQAdEA6gA5CBbiEdEDOPIbcciDjZUNeq4ljW1rJ/i6xrId3/uibRDZQsqB+CnTeoWY5SKAkx8kDlCSg556Zz1TF/FC/zwtcQ4lBMl4XpaCL0yEvlUIBAdMz8zlVF8Nf0j2UGJjx/tqTBV3c8McbVNCsZ74H25Z3MgS6gxJOZWK3PQip88qibcsrmZA62/e8FtLrnMO8FKzrxoIuRyF07pmvDNpiQuts33cghs4Sp2Ry0AhK+IHZsb7aycTSWVI7mVg62/21F4qjs6SDGqx1phQq/jduVjaiZjJ6nflRj1UjKjdwbJ0llcnE1llSmkwTOktKk2lCZ3tseWd0nSVH1W5KZ0lJMs3oLClMZqNzChU062I5cZlRmEwonY2Zz13mHKjdtM6Sg2RC6Mx/K19dCyksJszZGU/wwNrAbegssZLhU+It1LjpbM3cXqx05nivoIDnsz2/GTzZFRNCZwOa+Gzc/Xy0q7MkWVfUCbBXkGkK2RXDG3cIJfnmVZiqmBBn55XO5+bD6XeosJi2dZb8Aef4sLEchV9aAAAAAElFTkSuQmCC"
                  />
                </HeadingContainer>
              </TilbudLayout>
            ) : (
              <TestDriveH1>
                {brandCode === "DS"
                  ? `Få tilbud på ${selectedCar}`
                  : `Få tilbud på en ${getBrand(
                    brandCode
                  )} ${selectedCar}`}{" "}
              </TestDriveH1>
            )}
            <TestDriveIngress>{texts?.ingress}</TestDriveIngress>
          </TitleContainer>
          <Container>
            <InputContainer>
              <FieldContainer>
                <label className="p2" htmlFor="fname">
                  Fornavn
                  <Abbr title="Påkrevd">*</Abbr>
                </label>
                <Field id="fname" name="fname" type="text" />
                {touched.fname && errors.fname ? (
                  <FieldErrorText>{errors.fname}</FieldErrorText>
                ) : null}
              </FieldContainer>

              <FieldContainer>
                <label className="p2" htmlFor="lname">
                  Etternavn
                  <Abbr title="Påkrevd">*</Abbr>
                </label>
                <Field id="lname" name="lname" type="text" />
                {touched.lname && errors.lname ? (
                  <FieldErrorText>{errors.lname}</FieldErrorText>
                ) : null}
              </FieldContainer>

              <FieldContainer>
                <label className="p2" htmlFor="email">
                  E-post
                  <Abbr title="Påkrevd">*</Abbr>
                </label>
                <Field id="email" name="email" type="text" />
                {touched.email && errors.email ? (
                  <FieldErrorText>{errors.email}</FieldErrorText>
                ) : null}
              </FieldContainer>

              <FieldContainer>
                <label className="p2" htmlFor="phone">
                  Mobilnummer
                  <Abbr title="Påkrevd">*</Abbr>
                </label>
                <Field
                  id="phone"
                  name="phone"
                  type="tel"
                  required
                  autoComplete="tel"
                  inputMode="numeric"
                />
                {touched.phone && errors.phone ? (
                  <FieldErrorText>{errors.phone}</FieldErrorText>
                ) : null}
              </FieldContainer>

              <DealerLookup
                noDealerFoundText={props.texts?.noDealerFoundText}
              />
              {texts?.checkboxText ? (
                <NewsLetterContainer className="newsletter">
                  <div>
                    <Field id="newsletter" name="newsletter" type="checkbox" />
                  </div>
                  <div className="no-m">
                    <label htmlFor="newsletter">
                      <p className="p2">{texts?.checkboxText}</p>
                    </label>
                  </div>
                </NewsLetterContainer>
              ) : (
                ""
              )}
              {brandCode === "OPE" ? (
                <SubmitButtonTestDriveOpel type="submit" disabled={submitting}>
                  <img
                    alt="Chevron.svg"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDcgMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNi4zNjU4MyA3LjY5MDg4SDMuMzk4MzhMMC40NTMxMjUgMC43OTA4ODJIMy40MjYxMkw2LjM2NTgzIDcuNjkwODhaTTYuMzY1ODMgNy42OTA4OEgzLjM5ODM4TDAuNDUzMTI1IDE0LjU5MDlIMy40MjYxMkw2LjM2NTgzIDcuNjkwODhaIiBmaWxsPSIjRjdGRjE0Ii8+Cjwvc3ZnPgo="
                  />
                  Send inn
                </SubmitButtonTestDriveOpel>
              ) : (
                <SubmitButtonTestDrive type="submit" disabled={submitting}>
                  Send inn
                </SubmitButtonTestDrive>
              )}

              {windowSize <= 1200 && (
                <TestDriveBackButton type="button" onClick={() => setStep(0)}>
                  <p>Velg en annen modell</p>
                </TestDriveBackButton>
              )}
              {texts?.userAgreementText && (
                <BlockContentContainer>
                  <BlockContent
                    blocks={texts.userAgreementText}
                    serializers={serializers}
                  />
                </BlockContentContainer>
              )}
            </InputContainer>
          </Container>
          <CarInfoContainer>
            <ImageInfoContainer>
              <img
                src={urlFor(carInfo.image)
                  .height(875)
                  .auto("format")
                  .url()
                  ?.toString()}
                alt={carInfo.image.alttext ?? "Bil"}
              />

              <InfoContainer>
                <span className="h6">{selectedCar}</span>
                <Divider>/</Divider>
                <p className="p2">{carInfo?.chassis}</p>
                <Divider>/</Divider>
                <p className="p2">{carInfo?.fuel}</p>
              </InfoContainer>
            </ImageInfoContainer>
            <TestDriveBackButton
              className="remove"
              type="button"
              onClick={() => setStep(0)}
            >
              <p>Velg en annen modell</p>
            </TestDriveBackButton>
          </CarInfoContainer>
        </PageLayout>
      ) : (
        <div>Noe gikk galt</div>
      )}
    </>
  );
};

export default TestDrivePersonalia;
